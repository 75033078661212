@mixin svg-add-favorite-dims-bywidth($width) {
  max-height: $width/15.458*14.371;
}
@mixin svg-add-favorite-dims-byheight($height) {
  max-width: $height/14.371*15.458;
}
$svg-add-favorite-dims-width: 15.458px;
$svg-add-favorite-dims-height: 14.371px;
@mixin svg-calendar-dims-bywidth($width) {
  max-height: $width/13.856*14.17;
}
@mixin svg-calendar-dims-byheight($height) {
  max-width: $height/14.17*13.856;
}
$svg-calendar-dims-width: 13.856px;
$svg-calendar-dims-height: 14.17px;
@mixin svg-checked-dims-bywidth($width) {
  max-height: $width/611.997*611.998;
}
@mixin svg-checked-dims-byheight($height) {
  max-width: $height/611.998*611.997;
}
$svg-checked-dims-width: 611.997px;
$svg-checked-dims-height: 611.998px;
@mixin svg-comment-dims-bywidth($width) {
  max-height: $width/611.997*611.998;
}
@mixin svg-comment-dims-byheight($height) {
  max-width: $height/611.998*611.997;
}
$svg-comment-dims-width: 611.997px;
$svg-comment-dims-height: 611.998px;
@mixin svg-download-dims-bywidth($width) {
  max-height: $width/13.86*14.17;
}
@mixin svg-download-dims-byheight($height) {
  max-width: $height/14.17*13.86;
}
$svg-download-dims-width: 13.86px;
$svg-download-dims-height: 14.17px;
@mixin svg-email-dims-bywidth($width) {
  max-height: $width/512*512;
}
@mixin svg-email-dims-byheight($height) {
  max-width: $height/512*512;
}
$svg-email-dims-width: 512px;
$svg-email-dims-height: 512px;
@mixin svg-filter-dims-bywidth($width) {
  max-height: $width/17.012*17.509;
}
@mixin svg-filter-dims-byheight($height) {
  max-width: $height/17.509*17.012;
}
$svg-filter-dims-width: 17.012px;
$svg-filter-dims-height: 17.509px;
@mixin svg-like-dims-bywidth($width) {
  max-height: $width/611.997*611.998;
}
@mixin svg-like-dims-byheight($height) {
  max-width: $height/611.998*611.997;
}
$svg-like-dims-width: 611.997px;
$svg-like-dims-height: 611.998px;
@mixin svg-link-dims-bywidth($width) {
  max-height: $width/28.35*28.35;
}
@mixin svg-link-dims-byheight($height) {
  max-width: $height/28.35*28.35;
}
$svg-link-dims-width: 28.35px;
$svg-link-dims-height: 28.35px;
@mixin svg-map-dims-bywidth($width) {
  max-height: $width/611.997*611.998;
}
@mixin svg-map-dims-byheight($height) {
  max-width: $height/611.998*611.997;
}
$svg-map-dims-width: 611.997px;
$svg-map-dims-height: 611.998px;
@mixin svg-media-doc-dims-bywidth($width) {
  max-height: $width/28.35*28.35;
}
@mixin svg-media-doc-dims-byheight($height) {
  max-width: $height/28.35*28.35;
}
$svg-media-doc-dims-width: 28.35px;
$svg-media-doc-dims-height: 28.35px;
@mixin svg-media-photo-dims-bywidth($width) {
  max-height: $width/28.35*28.35;
}
@mixin svg-media-photo-dims-byheight($height) {
  max-width: $height/28.35*28.35;
}
$svg-media-photo-dims-width: 28.35px;
$svg-media-photo-dims-height: 28.35px;
@mixin svg-menu--apartments-dims-bywidth($width) {
  max-height: $width/512*512;
}
@mixin svg-menu--apartments-dims-byheight($height) {
  max-width: $height/512*512;
}
$svg-menu--apartments-dims-width: 512px;
$svg-menu--apartments-dims-height: 512px;
@mixin svg-menu--documents-dims-bywidth($width) {
  max-height: $width/850.391*850.391;
}
@mixin svg-menu--documents-dims-byheight($height) {
  max-width: $height/850.391*850.391;
}
$svg-menu--documents-dims-width: 850.391px;
$svg-menu--documents-dims-height: 850.391px;
@mixin svg-menu--immigration-dims-bywidth($width) {
  max-height: $width/480*480;
}
@mixin svg-menu--immigration-dims-byheight($height) {
  max-width: $height/480*480;
}
$svg-menu--immigration-dims-width: 480px;
$svg-menu--immigration-dims-height: 480px;
@mixin svg-menu--library-dims-bywidth($width) {
  max-height: $width/850.391*850.391;
}
@mixin svg-menu--library-dims-byheight($height) {
  max-width: $height/850.391*850.391;
}
$svg-menu--library-dims-width: 850.391px;
$svg-menu--library-dims-height: 850.391px;
@mixin svg-menu--moving-dims-bywidth($width) {
  max-height: $width/56.691*56.689;
}
@mixin svg-menu--moving-dims-byheight($height) {
  max-width: $height/56.689*56.691;
}
$svg-menu--moving-dims-width: 56.691px;
$svg-menu--moving-dims-height: 56.689px;
@mixin svg-my-favorites-dims-bywidth($width) {
  max-height: $width/611.997*611.998;
}
@mixin svg-my-favorites-dims-byheight($height) {
  max-width: $height/611.998*611.997;
}
$svg-my-favorites-dims-width: 611.997px;
$svg-my-favorites-dims-height: 611.998px;
@mixin svg-new-dims-bywidth($width) {
  max-height: $width/25.891*25.892;
}
@mixin svg-new-dims-byheight($height) {
  max-width: $height/25.892*25.891;
}
$svg-new-dims-width: 25.891px;
$svg-new-dims-height: 25.892px;
@mixin svg-not-available-dims-bywidth($width) {
  max-height: $width/70.74*17.41;
}
@mixin svg-not-available-dims-byheight($height) {
  max-width: $height/17.41*70.74;
}
$svg-not-available-dims-width: 70.74px;
$svg-not-available-dims-height: 17.41px;
@mixin svg-phone-dims-bywidth($width) {
  max-height: $width/20.71*21.398;
}
@mixin svg-phone-dims-byheight($height) {
  max-width: $height/21.398*20.71;
}
$svg-phone-dims-width: 20.71px;
$svg-phone-dims-height: 21.398px;
@mixin svg-whatsapp-dims-bywidth($width) {
  max-height: $width/90*90;
}
@mixin svg-whatsapp-dims-byheight($height) {
  max-width: $height/90*90;
}
$svg-whatsapp-dims-width: 90px;
$svg-whatsapp-dims-height: 90px;
@mixin svg-www-dims-bywidth($width) {
  max-height: $width/20.71*21.398;
}
@mixin svg-www-dims-byheight($height) {
  max-width: $height/21.398*20.71;
}
$svg-www-dims-width: 20.71px;
$svg-www-dims-height: 21.398px;
