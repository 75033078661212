

.map-wrapper {
    margin-top: 1px;
    border: 1px solid $orange;
    width: 100%;
    height: calc(100% - 1px);
    position: relative;
    display: none;

    @media (min-width: 1025px) {
        height: 700px;
    }
}

.map {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}



.catalog {
    
    &__block {
        margin-top: 25px;

        &:first-child {
            margin-top: 0;
        }
    }

    &__banner {
        margin-top: 25px;
    }

    &__date {
        font-family: $helvetica-lt;
        color: $orange;
        font-size: 17px;
        font-weight: 300;

        &::after {
            content: '';
            display: block;
            background: rgb(243,120,53);
            background: -moz-linear-gradient(left, rgba(243,120,53,1) 0%, rgba(255,255,255,1) 100%);
            background: -webkit-linear-gradient(left, rgba(243,120,53,1) 0%,rgba(255,255,255,1) 100%);
            background: linear-gradient(to right, rgba(243,120,53,1) 0%,rgba(255,255,255,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f37835', endColorstr='#ffffff',GradientType=1 );
            max-width: 610px;
            width: 100%;
            height: 3px;
            margin-top: 5px;
        }
    }

    &__agenda {
        display: none;

        &--active {
            display: block;
        }
    }

    &__agenda-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $orange;
        position: absolute;
        bottom: 10px;
        left: 0;
        width: calc(100% - 40px);
        height: 30px;
        margin: 0 20px;
        font-size: $small_font;
        font-family: $helvetica-cyr;
        color: #fff;

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 20px;
            top: 10px;
            width: 16px;
            height: 9px;
            background: url(../images/agenda-arrow.png) no-repeat 0 0;
            transform: rotateX(180deg);
            transition: all 0.5s ease;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            right: 20px;
            top: 10px;
            width: 16px;
            height: 9px;
            background: url(../images/agenda-arrow.png) no-repeat 0 0;
            transform: rotateX(180deg);
            transition: all 0.5s ease;
        }

        &--active {
            &::before {
                transform: rotate(0);
            }

            &::after {
                transform: rotate(0);
            }
        }
    }

    &__favorites-block {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.catalog-banner {
    position: relative;
    display: inline-block;

    &__close {
        position: absolute;
        top: 5px;
        right: 10px;
        cursor: pointer;

        &::after {
            color: white;
            font-size: 24px;
            content: '✖';
            display: block;
        }
    }

    &__image {
        max-width: 100%;
    }
}

.catalog-item {
    margin: 15px 0 30px;

    &:last-child {
        margin-bottom: 0;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__image {
        background: url(../images/catalog-item-img.jpg) no-repeat center center;
        background-size: cover;
        width: 110px;
        height: 98px;
        display: flex;
        position: relative;

        &--excel {
            background-image: url(../images/icons/default-prev-excel.svg);/*base64:skip*/
        }

        &--doc {
            background-image: url(../images/icons/default-prev-doc.svg);/*base64:skip*/
        }

        &--ppt {
            background-image: url(../images/icons/default-prev-ppt.svg);/*base64:skip*/
        }

        &--not-available {
            &::after {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: url(../images/not-available.png) no-repeat 0 bottom;
                background-size: contain;
            }
        }

        &--new {
            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: url(../images/sprite.svg#new) no-repeat center center;
                background-size: contain;
                transform: scale(0.7);
            }
        }
    }

    &__main {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
        width: calc(100% - 110px);
    }

    &__address {
        font-family: $helvetica-lt;
        overflow-wrap: break-word;
        word-wrap: break-word;
        font-size: 12px;
        line-height: 140%;
        font-weight: 300;
        color: $dark-grey;
        width: 100%;
        text-align: left;
    }

    &__icons {
        height: 18px;
        width: 100%;
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        position: relative;
    }

    &__icon-wrapper {
        margin-right: 3px;
        height: 100%;
        max-width: 16.6%;

        &:last-child {
            margin-right: 0;
        }
    }

    &__icon {
        height: 100%;
        fill: $grey;
        cursor: pointer;
        max-width: 20px;

        &--active, &[data-comment] {
            fill: $orange;
        }
    }

    &__new {
        display: none;
    }

    &__not-available {
        display: none;
    }

    &__comment {
        margin-top: 6px;
        display: none;
        position: relative;

        &--active {
            padding: 20px;
            width: 100%;
            height: 160px;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            background: #fff;
        }
    }

    &__textarea {
        border: 1px solid $orange;
        width: 100%;
        height: 90px;
        resize: none;
        padding: 5px 10px;
        font-family: $helvetica-cyr;
        font-size: $small_font;
        position: relative;
        z-index: 1;
        background: #fff;
        display: block;
    }

    &__comment-controls {
        @include clearfix;
        margin-top: 7px;
        height: 30px;
    }

    &__comment-btn {
        &--save {
            float: left;
        }

        &--cancel {
            float: right;
        }
    }
}

.filter-block {

    &__title {
        font-size: 18px;
        color: $orange;
        text-align: center;
    }

    &__main {
        margin-top: 30px;
        padding: 0 30px;
    }

    &__row {
        @include clearfix;
        margin-bottom: 25px;
    }

    &__label {
        float: left;
        color: $orange;
        font-size: 16px;
    }

    &__sort {
        float: right;
    }

    &__sort-btn {
        border: 10px solid transparent;
        display: inline-block;
        vertical-align: top;
        height: 10px;
        position: relative;
        cursor: pointer;

        &--up {
            border-bottom: 10px solid $grey;
            top: -10px;
        }

        &--down {
            border-top: 10px solid $grey;
        }

        &--active {
            &.filter-block__sort-btn--up {
                border-bottom: 10px solid $orange;
            }

            &.filter-block__sort-btn--down {
                border-top: 10px solid $orange;
            }
        }
    }

    &__checkbox {
        float: right;
    }

    &__btn {
        text-align: center;
        margin-top: 30px;
    }

    &__view-block {
        float: left;
    }

    &__view-label {
        width: 40px;
        height: 15px;
        border: 2px solid $orange;
        display: block;
        border-radius: 10px;
        margin: 0 25px 0 40px;
        position: relative;
        cursor: pointer;

        &::after {
            content: '';
            display: block;
            width: 24px;
            height: 24px;
            border-radius: 24px;
            position: absolute;
            left: -16px;
            right: auto;
            top: -12px;
            background: $orange;
            border: 4px solid #fff;
            transition: all 0.3s ease;
        }
    }

    &__check {
        display: none;

        &:checked {
            + .filter-block__view-label::after {
                left: 24px;
            }
        }
    }
}

.my-favorites {
    padding: 0 15px 15px;
    padding-top: inherit;
    background: #fff;
    width: 100%;
    height: calc(100% - 50px);

    &__title {
        font-size: $small_font;
        color: $orange;
        text-align: center;
        height: 16px;
    }

    &__main {
        height: 100%;
        display: none;
    }

    &__list {
        height: calc(100% - 60px);
        overflow-y: scroll;
        margin: 20px 0;
        padding: 0 30px;
    }

    &__row {
        margin-bottom: 20px;
    }

    &__checkbox {
        display: inline-block;
        vertical-align: top;
    }

    &__label {
        color: $orange;
        font-family: $helvetica-cyr;
        font-weight: 300;
        font-size: $small_font;
        display: inline-block;
        vertical-align: top;
        margin-left: 10px;
        max-width: calc(100% - 40px);
        line-height: 140%;
        word-wrap: break-word;
    }

    &__controls {
        display: none;
        justify-content: space-between;
        height: 20px;
        max-width: 350px;
        margin: auto;

        &--active {
            display: flex;
        }
    }

    &__control-btn {
        font-size: $small_font;
        font-family: $helvetica-cyr;
        font-weight: 300;
        color: $dark-grey;
        cursor: pointer;
    }

    &--active {
        .my-favorites {
            &__main {
                display: block;
            }
        }
    }
}

.agenda {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 50px);
    padding: 0 15px 15px;
    padding-top: inherit;
    z-index: 3;

    &__title {
        color: $orange;
        font-size: $small_font;
        position: relative;
        text-align: center;
        height: 16px;
    }

    &__list {
        margin-top: 20px;
        padding: 0 30px;
        list-style-position: inside;
        height: calc(100% - 36px);
        list-style-type: decimal;
    }

    &__item {
        font-size: $small_font;
        font-weight: 300;
        font-family: $helvetica-cyr;
        color: $dark-grey;
        margin-bottom: 15px;
        line-height: 140%;
        display: list-item;
        counter-increment: line;
        text-decoration: none;
    }
}


.infrastructure {
    z-index: 1;
    position: relative;
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;

    @media (max-height: 630px) {
        &--active {
            height: calc(100% - 2px);
        }
    }

    &__header {
        background: $orange;
        padding: 0 10px;
        height: 38px;
        min-width: 210px;
        display: flex;
        align-items: center;
    }

    &__burger-label {
        width: 18px;
        height: 2px;
        background: #fff;
        display: block;
        position: relative;
        border-radius: 3px;
        padding: 6px 0;
        background-clip: content-box;
        box-sizing: content-box;
        cursor: pointer;

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            background: #fff;
            position: absolute;
            top: 0;
            border-radius: 3px;
            transition: all 0.2s ease;
        }

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            background: #fff;
            position: absolute;
            bottom: 0;
            border-radius: 3px;
            transition: all 0.2s ease;
        }
    }

    &__burger-input {
        display: none;

        &:checked {
            + label {
                background: transparent;

                &::after {
                    transform: rotate(45deg);
                    bottom: 6px;
                }

                &::before {
                    transform: rotate(-45deg);
                    top: 6px;
                }
            }
        }
    }

    &__title {
        color: #fff;
        margin-left: 12px;
        font-size: 25px;
        font-family: Helvetica, sans-serif;
        line-height: 100%;
        cursor: pointer;
    }

    &__list {
        padding: 10px;
        border-bottom: 1px solid $orange;
        border-right: 1px solid $orange;
        max-height: calc(100% - 38px);
        overflow: hidden;
        background: #fff;
        display: none;
    }

    &__row {
        margin-bottom: 5px;
        display: flex;
        align-items: center;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__name {
        flex-grow: 1;
        font-size: 13px;
        font-family: $helvetica-lt;
        text-transform: uppercase;
        color: $orange;
        margin-left: 10px;
        margin-top: 5px;
    }

    &__icon {
        margin-left: 15px;
    }
}

.map-popup {
    border: 2px solid $orange;
    padding: 10px;
    z-index: 100;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    min-height: 100%;

    &__cluster {
        color: #fff;
        font-weight: 600;
        font-size: $small_font;
    }

    &__header {
        color: $orange;
        font-family: $helvetica-cyr;
        font-size: 17px;
        font-weight: 500;
        text-align: center;
        padding: 0 50px 5px;
    }

    &__main {
        position: relative;
        width: 100%;
    }

    &__slider {
        width: 100%;
        position: relative;
    }

    &__slider-item {
        width: 100%;
        position: relative;
        outline: none;
    }

    &__image {
        background: url(../images/catalog-item-img.jpg) no-repeat 0 0;
        background-size: cover;
        height: 250px;
        width: 100%;
        position: relative;
        background-position: 0 0;
        background-repeat: no-repeat;

        &--team {
            background-image: url(../images/team.jpg);
            height: auto;
            min-height: 250px;
            background-size: contain;
        }

        &--excel {
            background-image: url(../images/icons/default-prev-excel.svg); /*base64:skip*/
        }

        &--doc {
            background-image: url(../images/icons/default-prev-doc.svg); /*base64:skip*/
        }

        &--ppt {
            background-image: url(../images/icons/default-prev-ppt.svg); /*base64:skip*/
        }
    }

    &__arrows {
    }

    &__arrow {
        position: absolute;
        width: 50px;
        height: 40px;
        top: 105px;
        cursor: pointer;

        img {
            margin-top: 15%;
        }

        &--prev {
            left: 0;
            padding-left: 10px;
            @include gradientToRight;
        }

        &--next {
            right: 0;
            text-align: right;
            padding-right: 10px;
            @include gradientToLeft;
        }
    }

    &__price {
        position: absolute;
        top: 0;
        left: 0;
        color: $orange;
        z-index: 1;
        padding-left: 5px;
        width: 50%;
        height: 50px;
        font-family: $helvetica-lt;
        font-size: 20px;
        line-height: 50px;
        @include gradientToRight;
    }

    &__date {
        $width: 40px;

        position: absolute;
        bottom: 0;
        right: 0;
        padding-left: 50px;
        color: $dark-grey;
        text-align: right;
        font-family: $helvetica-lt;
        padding: 10px;
        line-height: 120%;
        background-color: white;
        margin-left: $width;
        overflow: visible;

        &::before {
            display: block;
            position: absolute;
            left: -$width;
            top: 0;
            width: $width;
            bottom: 0;
            content: '';
            @include gradientToLeft;
        }
    }

    &__close {
        width: 20px;
        height: 20px;
        background: url(../images/close.png) no-repeat 0 0;
        background-size: contain;
        position: absolute;
        top: 5px;
        right: 10px;
        cursor: pointer;
    }

    &__icons {
        height: 23px;
        margin-top: 10px;
        position: relative;
        display: inline-block;
        vertical-align: top;
    }

    &__address {
        font-family: $helvetica-lt;
        font-size: 16px;
        line-height: 140%;
        font-weight: 300;
        color: $orange;
        width: 100%;
        text-align: left;
        margin-top: 15px;
    }

    &__link {
        color: $orange;
    }

    &__comment {
        margin-top: 10px;
        display: none;


        &--active {
            display: block;
        }
    }

    &__textarea {
        display: block;
        width: 100%;
        resize: none;
        height: 50px;
        border: 1px solid $orange;
    }

    &__buttons {
        margin-top: 5px;
        @include clearfix;
    }
}


@media (min-width: 380px) {
    .catalog-item {
        &__icon-wrapper {
            margin-right: 7px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

@media (min-width: 471px) {
    .catalog-item {
        &__main {
            flex-wrap: nowrap;
        }

        &__address {
            width: auto;
            padding-right: 10px;
        }

        &__icons {
            width: auto;
            margin-top: 0;
            min-width: 170px;
        }
    }

    .map-popup {
        position: relative;
        overflow-y: hidden;
        width: 280px;
        height: auto;
        min-height: 0;
        
        &--office {
            width: 400px;
        }
    }
}

@media (min-width: 641px) {
    
    .empty {
        font-size: 22px;

        &__logout {
            font-size: 22px;
        }
    }

    .catalog-item {
        &__icon-wrapper {
            margin-right: 10px;
        }
    }

    .catalog {

        &__agenda-btn {
            display: none;
        }

        &__agenda {
            display: block;
            position: relative;
            border-top: 1px solid $orange;
            padding: 20px;
            height: auto;
        }
    }


    .filter-block {

        &__title {
            font-size: 22px;
        }

        &__main {
            padding: 0;
        }
    }

    .side-box {
        max-height: calc(100vh - 50px);
    }

    .side-box--right {
        /*height: calc(100% - 50px);*/
        height: 500px;
    }

    .my-favorites {
        position: relative;
        padding: 20px;
        height: 70px;

        &__title {
            font-size: 22px;
            line-height: 140%;
            height: auto;
            position: relative;

            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 7px;
                right: 0;
                background: url(../images/menu-arrow.png) no-repeat 0 0;
                width: 14px;
                height: 8px;
            }
        }

        &__main {
            margin-top: 20px;
            height: calc(100% - 50px);
        }

        &__list {
            padding: 0;
            overflow: hidden;
            margin-bottom: 10px;
            height: calc(100% - 20px);
            overflow-y: scroll;
        }

        &__row {
            margin-bottom: 15px;
        }

        &__controls {
            &--active {
                display: block;
            }
        }

        &__control-btn {
            margin-right: 15px;

            &:last-child {
                margin-top: 0;
            }
        }

        &--active {
            height: calc(100% - 70px);

            .my-favorites {
                &__title::after {
                    transform: rotate(180deg);
                }

                &__main {
                    display: block;
                }
            }
        }
    }

    .agenda {
        &__title {
            height: auto;
            font-size: 22px;
            line-height: 140%;

            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 7px;
                right: 0;
                background: url(../images/menu-arrow.png) no-repeat 0 0;
                width: 14px;
                height: 8px;
            }
        }

        &__list {
            padding: 0;
            display: none;
            height: calc(100% - 70px);
        }

        &--active {
            height: calc(100% - 70px);

            .agenda {
                &__title::after {
                    transform: rotate(180deg);
                }

                &__list {
                    display: block;
                }
            }
        }
    }
}

@media (min-width: 760px) {
    .catalog-item {
        &__image {
            &::before {
                display: none;
            }

            &::after {
                display: none;
            }
        }

        &__new {
            width: 40px;
            height: 40px;
            fill: $orange;
            display: block;
            margin-right: 15px;

            &--active {
                background: url("../images/new-icon.png") no-repeat center center;
                background-size: contain;
            }
        }

        &__not-available {
            width: 120px;
            min-width: 105px;
            height: 29px;
            line-height: 29px;
            display: block;
            text-align: center;
            margin-right: 15px;

            &--active {
                color: #fff;
                font-family: $helvetica-lt;
                font-size: 15px;
                font-weight: 600;
                background: $orange;
                padding: 0 5px;
            }
        }

        &__address {
            margin-right: 15px;
            padding-right: 0;
            font-size: 16px;
        }
    }
}

@media (min-width: 1025px) {
    .catalog-item {
        &__address {
            font-size: 18px;
            margin-left: 20px;
            max-width: 230px;
        }

        &__icons {
            height: 20px;
        }

        &__icon-wrapper {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }

        &__not-available--active {
            font-size: 16px;
        }
    }
}

@media (min-width: 1510px) {

    .my-favorites {
        &__title {
            font-size: 22px;
            text-align: left;
        }

        &__list {
            /*height: 265px;*/
            overflow-y: scroll;
        }
    }

    .agenda {
        &__title {
            font-size: 22px;
            text-align: left;
        }

        &__list {
            overflow-y: scroll;
            height: 265px;
        }
    }
}


$header_height: 70px;

.documents {

    &__sort-wrapper {
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }

    &__label {
        color: $dark-grey;
        font-family: $helvetica-lt;
        font-size: $small_font;
        font-weight: 300;
        display: inline-block;
        vertical-align: top;
    }

    &__sort {
        text-align: right;
    }

    &__sort-controls {
        display: inline-block;
        vertical-align: top;
    }

    &__title {
        font-size: 25px;
        color: $orange;
        font-family: $helvetica-cyr;
        text-align: center;
        width: 100%;
    }

    &__list {
        list-style-type: none;
        padding-left: 0;
        margin: 0;
        width: 99%;
    }

    &__item {
        font-size: $small_font;
        font-weight: 300;
        font-family: $helvetica-lt;
        color: $dark-grey;
        line-height: 140%;
        width: calc(100% - 50px);

        &-title {
            padding: 10px 30px 10px 0;
        }

        &-link {
            color: $dark-grey;
        }

        &-controls {
            padding: 10px 0;
            width: 45px;
            position: relative;
        }

        &-control {
            width: 17px;
            height: 17px;
            cursor: pointer;
            padding-right: 3px;
            box-sizing: content-box;
            display: inline-block;
            vertical-align: middle;
        }
    }



    @media (min-width: 641px) {
        &__list {
            width: auto;
        }

        &__item {
            width: auto;

            &-controls {
                width: auto;
            }

            &-control {
                padding-right: 10px;
                box-sizing: content-box;
            }
        }
    }
}


.moving {

    $top_vert_margin: 7px;

    &__infos {
    }

    &__infos + &__steps {
        margin-top: 30px;
    }

    &__info {
        margin: $top_vert_margin 0;
    }

    &__contacts {
        white-space: nowrap;
        display: inline-block;

        &::before {
            content: ' | ';
        }
    }
    &__contact {
        display: inline-block;
        width: 1.2em;
        height: 1.2em;
        color: $orange;
        fill: $orange;
        margin-left: 0.5em;
        vertical-align: bottom;

        &:first-child{
            margin-left: 0;
        }

        svg {
            fill: inherit;
            width: 100%;
            height: 100%;
        }
    }

    &__em {
        color: $orange;
    }

    &__history {
        margin: $top_vert_margin 0;
        text-align: left;
        width: 100%;

        @media (min-width: 600px) {
            text-align: right;
        }
    }

    &__history-item {
        display: inline-block;
        border: 1px solid $orange;
        color: $orange;
        padding: 5px;
        margin-bottom: 7px;
        text-decoration: none;

        &_in-progress {
            background-color: $orange;
            color: white;
        }
    }

    &__steps {
    }

    &__step {
        margin: 3px 0;
    }

    &-step {
        &__wrapper {
            @include clearfix();
            position: relative;
        }

        &__comment {
            border: 1px solid $orange;
            width: 100%;
            min-height: 4em;
            padding: 5px 10px;
            font-family: $helvetica-cyr;
            font-size: $small_font;
            background: #fff;
            display: block;
            margin: 10px 0;

            &_active {
            }
        }

        &__icon {
            display: block;
            width: 60px;
            height: 60px;
            float: left;
            background-color: $grey;
            fill: white;
            margin-right: 15px;
            fill: $dark-grey;


            svg {
                height: 100%;
                width: 100%;
                fill: inherit;
            }

            &_active {
                background-color: $orange;
                fill: white;
            }

            @media (min-width: 400px) {
                width: 91px;
                height: 91px;
            }
        }

        &__title {
        }

        &__more {
            font-size: $small_font;

            &::after {
                content: '';
                display: inline-block;
                background: url(../images/menu-arrow.png) no-repeat 0 0;
                width: 14px;
                height: 8px;
                margin-left: 0.5em;
            }

            &_active {
                &::after {
                    transform: rotateX(180deg);
                }
            }
        }

        $media_btn_height: 29px;

        &__media {
            display: inline-block;
            position: absolute;
            right: 0;
            top: calc(50% - #{$media_btn_height}/2);
            height: $media_btn_height;
        }

        &__media-icon {
            display: inline-block;
            position: relative;
            width: $media_btn_height;
            height: $media_btn_height;

            & svg {
                fill: $orange;
                height: 100%;
                width: 100%;
            }
            /*&::after {
                content: '';
                display: inline-block;
                width: $media_btn_height;
                height: $media_btn_height;
            }*/
            &_active {
            }

            &_pics {
                /*background-image: url(../images/icons/media-photo.svg);*/
            }

            &_files {
                /*background-image: url(../images/icons/media-doc.svg)*/
            }
        }
    }
}

.download-list-box {
    right: 0;
    position: absolute;
    display: none;
    opacity: 1;
    background-color: white;
    border: solid 1px $orange;
    z-index: 1;
    max-width: 50vw;

    &_active {
        display: block;
        opacity: 0;
    }

    &__item {
        margin: 10px 10px;
        max-width: 100%;
        text-overflow: ellipsis;

        &_all {
        }
    }
}


.welcome {
    color: #fff;
    font-family: $helvetica-cyr;
    font-size: 96px;
    font-weight: 300;
    line-height: 140%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 60px);
    text-align: center;
    z-index: 10;

    &--disabled {
        display: none;
    }

    @media (max-width: 1300px) {
        font-size: 60px;
    }

    @media (max-width: 700px) {
        font-size: 40px;
    }

    @media (max-width: 450px) {
        font-size: 28px;
    }
}

.copyright {
    position: absolute;
    padding-right: inherit;
    padding-bottom: inherit;
    position: absolute;
    bottom: 0;
    right: 0;
    font-family: $helvetica-cyr;
    color: #fff;
    font-size: $small_font;
    z-index: 10;

    &__link {
        font-family: $helvetica-cyr;
        color: #fff;
        font-size: $small_font;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}