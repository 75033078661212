@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Medium.eot');
    src: local('HelveticaNeueCyr-Medium'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Medium.woff') format('woff'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display:swap;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Heavy.eot');
    src: local('HelveticaNeueCyr-Heavy'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Heavy.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Heavy.woff') format('woff'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display:swap;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-HeavyItalic.eot');
    src: local('HelveticaNeueCyr-HeavyItalic'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-HeavyItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-HeavyItalic.woff') format('woff'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display:swap;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Black.eot');
    src: local('HelveticaNeueCyr-Black'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Black.woff') format('woff'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display:swap;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-UltraLightItalic.eot');
    src: local('HelveticaNeueCyr-UltraLightItalic'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-UltraLightItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-UltraLightItalic.woff') format('woff'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-UltraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display:swap;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-BlackItalic.eot');
    src: local('HelveticaNeueCyr-BlackItalic'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-BlackItalic.woff') format('woff'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display:swap;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-ThinItalic.eot');
    src: local('HelveticaNeueCyr-ThinItalic'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-ThinItalic.woff') format('woff'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display:swap;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-MediumItalic.eot');
    src: local('HelveticaNeueCyr-MediumItalic'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-MediumItalic.woff') format('woff'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display:swap;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-LightItalic.eot');
    src: local('HelveticaNeueCyr-LightItalic'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-LightItalic.woff') format('woff'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display:swap;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-UltraLight.eot');
    src: local('HelveticaNeueCyr-UltraLight'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-UltraLight.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-UltraLight.woff') format('woff'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display:swap;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-BoldItalic.eot');
    src: local('HelveticaNeueCyr-BoldItalic'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-BoldItalic.woff') format('woff'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display:swap;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Italic.eot');
    src: local('HelveticaNeueCyr-Italic'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Italic.woff') format('woff'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Italic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display:swap;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Roman.eot');
    src: local('HelveticaNeueCyr-Roman'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Roman.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Roman.woff') format('woff'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Roman.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display:swap;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Bold.eot');
    src: local('HelveticaNeueCyr-Bold'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Bold.woff') format('woff'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display:swap;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Light.eot');
    src: local('HelveticaNeueCyr-Light'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Light.woff') format('woff'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display:swap;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Thin.eot');
    src: local('HelveticaNeueCyr-Thin'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Thin.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Thin.woff') format('woff'),
    url('../fonts/Helvetica_Neue_Cyr/HelveticaNeueCyr-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display:swap;
}

@font-face {
    font-family: 'HelveticaNeueLT';
    src: url('../fonts/HelvetivaNeueLTStd/helveticaneueltstd-lt.woff') format('woff'),
    url('../fonts/HelvetivaNeueLTStd/helveticaneueltstd-lt.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display:swap;
}