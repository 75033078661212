    @import "fonts";
    @import "_sprite.scss";
    @import "_sprite-moving.scss";

    $helvetica-cyr: 'HelveticaNeueCyr', Arial, sans-serif;
    $helvetica-lt: 'HelveticaNeueLT', Arial, sans-serif;

    $orange: #f37835;
    $dark-grey: #4f4f4f;
    $grey: #bcbcbc;
    $header_height: 25px;
    $header_pad: 20px;
    $small_font: 14px;

    @mixin clearfix() {
        &::after {
            content: '';
            display: table;
            clear: both;
        }
    }

    @mixin gradientToRight() {
        background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
    }

    @mixin gradientToLeft() {
        background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
        background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
    }

    @mixin svg-menu-byheight($height) {
        height: $height;

        &--apartments {
            @include svg-menu--apartments-dims-byheight($height);
        }

        &--immigration {
            @include svg-menu--immigration-dims-byheight($height);
        }

        &--moving {
            @include svg-menu--moving-dims-byheight($height);
        }

        &--documents {
            @include svg-menu--documents-dims-byheight($height);
        }

        &--library {
            @include svg-menu--library-dims-byheight($height);
        }
    }