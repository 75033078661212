@import "_base.scss";
    * {
        box-sizing: border-box;
    }

    html, body {
        min-height: 100%;

        @media (max-height: 470px) {
            width: 100%;
            height: 100%;
        }
    }

    a {
        color: $orange;
    }

.btn {
    background: $orange;
    color: #fff;
    font-weight: 300;
    font-family: $helvetica-cyr;
    font-size: $small_font;
    padding: 0 20px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    cursor: pointer;
    border: 1px solid $orange;

    &--white {
        background: #fff;
        border: 1px solid $orange;
        color: $dark-grey;
    }

    &--inline {
        font-size: inherit;
        height: auto;
        line-height: normal;
    }
}

    .checkbox {
        &__input {
            display: none;

            &:checked {
                & + .checkbox__label {
                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 2px;
                        left: 2px;
                        width: 12px;
                        height: 12px;
                        background: $orange;
                        border-radius: 1px;
                    }

                    &--circe {
                        &::after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 3px;
                            left: 3px;
                            width: 8px;
                            height: 8px;
                            border-radius: 9px;
                            background: $orange;
                        }
                    }
                }
            }
        }

        &__label {
            display: block;
            width: 20px;
            height: 20px;
            border: 2px solid $orange;
            border-radius: 3px;
            background: none;
            position: relative;
            cursor: pointer;

            &--circe {
                border-width: 1px;
                width: 16px;
                height: 16px;
                border-radius: 16px;
            }
        }
    }

    .sort-btn {
        border: 10px solid transparent;
        display: inline-block;
        vertical-align: top;
        height: 10px;
        position: relative;
        cursor: pointer;

        &--up {
            border-bottom: 10px solid $grey;
            top: -10px;
        }

        &--down {
            border-top: 10px solid $grey;
        }

        &--active {
            &.sort-btn {
                &--up {
                    border-bottom: 10px solid $orange;
                }

                &--down {
                    border-top: 10px solid $orange;
                }
            }
        }
    }

    .main-bkg {
        background: $orange; /* Old browsers */
        background: -moz-linear-gradient(45deg, #eec863 0%, #eb3b00 50%, #eb3b00 51%, #eec461 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(45deg, #eec863 0%,#eb3b00 50%,#eb3b00 51%,#eec461 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(45deg, #eec863 0%,#eb3b00 50%,#eb3b00 51%,#eec461 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    .error p {
        width: 100%;
        text-align: center;
        font-size: 16px;
        padding: 10px 0;
        z-index: 10;
        color: #fff;
        text-shadow: 0px 0px 3px black;
        background-color: $orange;
        position: fixed;

        a {
            color: #fff;
        }
    }

    .content-wrapper {
        padding: 20pt;
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        max-width: 1920px;
        margin: auto;
    }

    .header {
        width: 100%;
        height: 60px;
        margin: 0 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--internal {
            display: none;
        }

        &__logo {
            width: 230px;
            height: 100%;
            background: url(../images/logo.png) no-repeat 0 center;
            background-size: contain;
            display: block;
            float: left;
        }

        &__logout {
            color: #fff;
            font-family: $helvetica-lt;
            font-size: $small_font;
            display: block;
            font-weight: 400;
            float: right;
            text-align: right;
            line-height: 100%;
            text-decoration: none;
            margin-top: 20px;
        }
    }

    $main-menu-height: 35px;

    .main-menu {
        height: $main-menu-height;
        width: 100%;
        margin-top: 20px;
        display: flex;
        order: 2;

        &__wrapper {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: flex-end;
        }

        &__text {
            display: none;
        }

        &__item {
            height: 80%;
            position: relative;
            text-align: center;
            text-decoration: none;
            display: block;

            &--active {
                height: 100%;

                .main-menu__icon {
                    @include svg-menu-byheight($main-menu-height)
                }
            }
        }

        &__icon-wrapper {
            display: inline-block;
            position: relative;
            vertical-align: top;
            height: 100%;
        }

        &__icon {
            height: 100%;
            position: relative;
            display: inline-block;
            vertical-align: top;
            fill: white;
            max-width: 100%;

            @include svg-menu-byheight(0.8*$main-menu-height)
        }

        &__count {
            display: block;
            position: absolute;
            bottom: -8px;
            right: -8px;
            width: 20px;
            height: 20px;
            background: red;
            border-radius: 20px;
            border: 2px solid #fff;
            color: #fff;
            font-family: $helvetica-cyr;
            font-size: 11px;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
        }

        &--internal {
            .main-menu {
                &__wrapper {
                    /*height: 100%;*/
                }

                &__item {
                    flex-basis: 20%;
                    flex-shrink: 0;
                    flex-grow: 0;

                    &--active {

                        &::after {
                            content: '';
                            display: block;
                            position: absolute;
                            border: 10px solid transparent;
                            border-top: 10px solid #fff;
                            top: -20px;
                            z-index: 1;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
            }
        }

        &--index {
            margin: 0;
            position: absolute;
            top: 80px;
            left: 0;
            width: 100%;
            height: calc(100% - 140px);
            align-items: center;
            justify-content: center;
            display: none;
            align-content: center;
            flex-wrap: wrap;
            z-index: 9;

            .main-menu {
                &__wrapper {
                    max-width: 900px;
                    width: 100%;
                    padding: 0 20px;
                    text-align: center;
                    justify-content: center;
                    flex-wrap: wrap;

                    @include clearfix();
                }

                &__item {
                    height: 100%;
                    text-align: center;
                    cursor: pointer;
                    text-decoration: none;
                    width: 33%;
                    display: inline-block;
                    vertical-align: top;
                    margin-right: -4px;
                    margin-bottom: 30px;
                    float: none;
                    padding: 0;

                    &::after {
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }

                &__icon-wrapper {
                }

                &__icon {
                    @include svg-menu-byheight(40px)
                }

                &__text {
                    display: block;
                    color: #fff;
                    font-family: $helvetica-cyr;
                    font-size: 12px;
                    font-weight: 300;
                    margin-bottom: 20px;
                    width: 100%;
                }
            }

            @media (max-height: 650px) {
            }

            @media (max-height: 470px) {
            }
        }
    }

    $side-box-head-pad: 50px;
    $side-box-offset: 30px;

    .side-box {
        display: none;
        position: absolute;
        top: 0;
        width: 100%;
        width: calc(100% - 2px);
        height: 100%;
        margin-top: 40px;
        height: calc(100% - #{$side-box-head-pad});
        left: 1px;
        transition: top .2s ease;
        background-color: white;
        padding: inherit;
        z-index: 9000;

        &--active {
            display: block;
        }

        @media (min-width: 641px) {
            width: auto;
            height: auto;
            min-width: 282px;
            max-width: 300px;
            padding: 20px;
            border: 1px solid #f37835;
            margin-top: $side-box-head-pad;
            max-height: calc(100% - #{$side-box-head-pad});
            height: auto;

            &--left {
                left: -$side-box-offset;
            }

            &--right {
                left: auto;
                right: -$side-box-offset;
            }
        }


        @media (max-height: 600px) and (max-width: 1024px) and (min-width: 641px) {
            position: fixed;
            z-index: 9000;
            margin-top: 0;
            height: 100%;
            max-height: 100%;

            &--left {
                left: 0;
            }

            &--right {
                right: 0;
            }
        }

        @media (min-width: 1510px) {
            $box-pad: 3px;

            margin-top: 0;
            border: none;
            top: 0;

            &--left {
                left: -$box-pad;
                transform: translateX(-100%);
            }

            &--right {
                right: -$box-pad;
                transform: translateX(100%);
            }
        }
    }

    .content {
        order: 1;
        background: #fff;
        padding: 15px 4px;
        overflow: auto;
        height: calc(100% - 55px);
        width: 100%;
        font-family: $helvetica-cyr;
        position: relative;
        overflow: hidden;

        &__title {
            font-size: 25px;
            color: $orange;
        }

        &__right {
            position: absolute;
            background: #fff;
            bottom: 56px;
            left: 0;
            display: none;
            padding: 5px 30px 30px;
            margin: 0 20px 20px;
            width: calc(100% - 40px);
            height: calc(100% - 136px);
            overflow-y: scroll;
            z-index: 9000;
            transition: top 0.2s ease;

            &--active {
                display: block;
            }
        }

        &__filter {
            position: absolute;
            background: #fff;
            bottom: 56px;
            left: 0;
            z-index: 1;
            display: none;
            transition: top 0.2s ease;

            &--active {
                display: block;
            }
        }

        &__header {
            height: $header_height;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 11px;
            padding-right: 11px;
            transition: top 0.2s ease;
        }

        &__icon-block {
            height: 100%;
            cursor: pointer;
        }

        &__header-icon {
            height: 100%;
            width: 30px;
            fill: $grey;
            cursor: pointer;

            &--active {
                fill: $orange;
            }
        }

        &__header-text {
            display: none;
        }

        &__inner {
            height: calc(100% - #{$header_height} - #{$header_pad});
            overflow-y: auto;
            margin-top: 20px;
            padding-left: 11px;
            padding-right: 11px;
            position: relative;
        }
    }

    noscript {
        width: 100%;
        text-align: center;
        font-size: 16px;
        padding: 10px 0;
        z-index: 10;
        color: #fff;

        a {
            color: #fff;
        }
    }

    .empty {
        color: #fff;
        padding: 0 10px;
        width: calc(100% - 20px);
        position: absolute;
        top: 50%;
        z-index: 10;
        transform: translateY(-50%);
        text-align: center;
        font-size: $small_font;
        font-family: $helvetica-cyr;
        display: none;
        left: 0;

        &__text {
            display: block;

            & a {
                color: inherit;
            }
        }

        &--active {
            display: block;
        }
    }

    @media (min-width: 401px) {
        .main-menu--index {
            .main-menu {
                &__icon {
                    @include svg-menu-byheight(50px)
                }

                &__text {
                    font-size: 18px;
                }
            }
        }
    }

    @media (min-width: 641px) {
        .content-wrapper {
            padding: 20pt 30px 30px;
        }

        .header {
            &--internal {
                display: flex;
            }
        }

        .content {
            height: calc(100% - 155px);
            position: relative;
            overflow: visible;

            &__header-icon {
                height: 25px;
                display: inline-block;
                vertical-align: middle;
            }

            &__header-text {
                font-size: 17px;
                display: inline-block;
                vertical-align: middle;
                color: $orange;
                font-weight: 300;
                font-family: $helvetica-cyr;
                margin: 0 10px;
            }

            &__right {
                top: 50px;
                right: -30px;
                left: auto;
                bottom: auto;
                margin: 0;
                width: 260px;
                height: 100%;
                max-height: calc(100% - 50px);
                border: 1px solid $orange;
                padding: 0;
                overflow: hidden;
            }

            &__filter {
                max-height: 100%;
                overflow-y: scroll;
            }
        }

        .main-menu {
            &--internal {
                $main-menu-height: 50px;

                height: $main-menu-height;
                justify-content: space-around;
                margin: 20px 0;

                .main-menu__icon {
                    @include svg-menu-byheight(0.8*$main-menu-height);
                }

                .main-menu__item--active {
                    .main-menu__icon {
                        @include svg-menu-byheight($main-menu-height);
                    }
                }
            }

            &__count {
                width: 22px;
                height: 22px;
                border-radius: 22px;
            }
        }


        .empty {
            font-size: 22px;

            &__logout {
                font-size: 22px;
            }
        }
    }

    @media (min-width: 760px) {

        .main-menu--index {
            .main-menu {
                &__item {
                    width: 16%;
                    margin-right: 5%;
                }
            }
        }
    }

    @media (min-width: 1025px) {

        .content-wrapper {
            &--internal {
                padding: 40px 50px 50px;
                position: relative;
                height: auto;
            }
        }

        .header {
            height: 90px;
            margin-bottom: 40px;
            transition: all 0.2s ease;
        }

        .content {
            order: 2;
            padding: 20px 5px;
            height: auto;
            margin: 30px auto 0;
            max-width: 1000px;

            &__header-text {
                font-size: 22px;
            }

            &__right {
                max-height: calc(100vh - 100px);
            }

            &__header {
                padding-left: 15px;
                padding-right: 15px;
            }

            &--fixed {
                margin-top: 0;

                .content__header {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    background: #fff;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    z-index: 9000;
                    height: auto;
                    min-height: 50px;
                }

                .content__inner {
                    margin-top: 75px;
                }
            }

            &__filter {
                max-height: calc(100vh - 50px);
            }
        }

        .main-menu {
            $main-menu-height: 70px;


            &--internal {
                order: 1;
                height: auto;
                margin: auto;
                max-width: 1000px;

                .main-menu {
                    &__item {

                        &--active {
                            &::before {
                                display: none;
                            }

                            &::after {
                                content: '';
                                display: block;
                                position: absolute;
                                border: 25px solid transparent;
                                border-bottom: 35px solid #fff;
                                bottom: -40px;
                                left: 50%;
                                transform: translateX(-50%);
                            }

                            .main-menu__icon {
                                @include svg-menu-byheight($main-menu-height);
                            }
                        }
                    }

                    &__icon {
                        @include svg-menu-byheight(0.93*$main-menu-height);
                    }
                }
            }

            &__item {
                text-align: center;
                min-width: 115px;
                height: 100%;
            }

            &__icon-wrapper {
                height: auto;
            }

            &__text {
                display: block;
                color: #fff;
                font-family: $helvetica-cyr;
                font-size: 23px;
                font-weight: 300;
                margin-bottom: 10px;
                width: 100%;
                text-align: center;
            }

            &__count {
                width: 25px;
                height: 25px;
                border-radius: 25px;
                font-size: 12px;
            }

            &--index {
                width: 100%;
                height: 100%;
                max-width: 100%;

                .main-menu {
                    &__icon {
                        @include svg-menu-byheight(70px)
                    }
                }
            }
        }
    }

    @media (min-width: 1510px) {
        .content-wrapper {
            &--internal {
                overflow: visible;
            }
        }

        .content {
            &__filter {
                left: -215px;
                top: 0;
                border: none;
            }

            &__right {
                right: -265px;
                top: 0;
                border: none;
                height: auto;
                max-height: 100vh;
            }

            &__title {
                font-size: 48px;
            }

            &__header {
                height: auto;
            }
        }
    }

    @media (min-width: 641px) and (max-width: 1024px) and (max-height: 600px) {
        .content {
            &__right--active {
                position: fixed;
                top: 0 !important;
                right: 0;
                height: 100%;
                max-height: 100%;

                .my-favorites {
                    height: 70px;

                    &__main {
                        height: calc(100% - 50px);
                    }

                    &__list {
                        height: calc(100% - 20px);
                        overflow-y: scroll;
                    }

                    &--active {
                        height: calc(100% - 71px);

                        .my-favorites {
                            &__list {
                                display: block;
                                max-height: 100%;
                            }
                        }
                    }
                }

                .agenda {
                    height: 71px;

                    &--active {
                        max-height: calc(100% - 50px);
                        height: 100%;

                        .agenda {
                            &__list {
                                max-height: 100%;
                                height: calc(100% - 70px);
                            }
                        }
                    }
                }
            }

            &__filter--active {
                position: fixed;
                top: 0 !important;
                left: 0;
                height: 100%;
                max-height: 100%;
            }
        }
    }

    .preloader {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 64px;
        height: 64px;
        background: url(../images/preloader.gif) center center no-repeat;
        display: none;
        margin-left: -32px;
        margin-top: -32px;
        background-color: rgba(255,255,255,0.7);
        border-radius: 50%;
        border: none;
        box-shadow: 0 0 33px 15px rgba(255,255,255,0.7);
        opacity: 0.5;
        display: none;
    }

    .tooltip {
        position: absolute;
        top: -35px;
        right: 0;
        padding: 5px;
        background: #fff;
        border: $orange 1px solid;
        font-size: $small_font;
        font-family: $helvetica-lt;
        color: $dark-grey;
        z-index: 10;

        &--copy {
            top: -45px;
        }

        &--documents {
            top: 3px;
            right: 50px;

            @media (min-width: 641px) {
                top: 3px;
                right: -160px;
            }
        }
    }

    .copy-link {
        margin-top: 10px;
        height: 30px;

        &--documents {
            margin-top: 0;
        }
    }

    .markered-text {
        margin: 10px 0;
        display: block;

        &::before {
            content: '';
            display: inline-block;
            width: 1.3em;
            height: 1.3em;
            background-position: center center;
            background-repeat: no-repeat;
            vertical-align: middle;
            margin-right: 1em;
            background-size: contain;
        }

        &--addr {
            &::before {
                background-image: url(../images/icons/map.svg);
            }
        }

        &--phone {
            &::before {
                background-image: url(../images/icons/phone.svg);
            }
        }

        &--www {
            &::before {
                background-image: url(../images/icons/www.svg);
            }
        }
    }

.login {
    margin: 1em;

    &__input {
        text-align: left;
        display: inline-block;
    }

    &__text {
        border: none;
        padding: 0.5em;
        margin: 0;
        text-align: center;
        display: block;

        &--error {
            outline: solid 1px red;
        }
    }

    &__error-note {
        font-size: 80%;

        &:before {
            content: '!';
            border: solid 1px;
            border-radius: 50% 50%;
            width: 1em;
            height: 1em;
            display: inline-block;
            text-align: center;
            margin-right: 0.5em;
        }
    }

    &__submit {
        display: none;
        border: none;
        padding: 0.5em;
        background-color: white;
    }
}